/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "../App.css";
import line from "../assets/Line.svg";
import circle from "../assets/Circle.svg";

import icon1 from "../assets/icons/1.png";
import icon2 from "../assets/icons/2.png";
import icon3 from "../assets/icons/3.png";
import icon4 from "../assets/icons/4.png";
import icon5 from "../assets/icons/5.png";
import icon6 from "../assets/icons/6.png";
import icon7 from "../assets/icons/7.png";

import mobile from "../assets/mobile.svg";
import mobileLogo from "../assets/mobile_logo.svg";
import { Link } from "react-router-dom";
import Modal from "../components/modal";

function Home() {
  const [loading, setLoading] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);

  const [open, setOpen] = useState(false);

  const icons = [
    {
      icon: icon1,
    },
    {
      icon: icon2,
    },
    {
      icon: icon3,
    },
    {
      icon: icon4,
    },
    {
      icon: icon5,
    },
    {
      icon: icon6,
    },
    {
      icon: icon7,
    },
  ];

  useEffect(() => {
    document.body.style.overflow = "hidden";
    setLoading(false);

    window.addEventListener("wheel", (ev) => {
      if (ev.deltaY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    });

    window.addEventListener("touchmove", (ev) => {
      if (ev.changedTouches[0].pageY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    });
  }, []);

  if (loading) {
    return (
      <div className="three-body">
        <div className="three-body__dot"></div>
        <div className="three-body__dot"></div>
        <div className="three-body__dot"></div>
      </div>
    );
  }

  return (
    <>
      {/* on desktop top section */}
      <div className={`${isScrolled ? "hidden" : ""}`}>
        <div className="flex items-center justify-center h-[64vh] overflow-hidden bg-transparent gap-8 flex-col">
          <h1
            className="text-4xl font-bold text-center opacity-0 translate-y-10 animate-slide-in"
            style={{ fontFamily: "Lexend", fontWeight: "bold" }}
          >
            SOCCO
          </h1>

          <div
            className="flex items-center gap-8 translate-y-10 animate-slide-in flex-col"
            style={{
              animation:
                "bounce 1600ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95)",
            }}
          >
            <img src={line} alt="Line" className="absolute" />
            <img src={circle} alt="Circle" className="mt-10" />
          </div>
        </div>

        <div
          className="lg:h-40 md:h-32 h-24 absolute bottom-0 w-full bg-black translate-y-10 animate-slide-in"
          style={{
            clipPath: "ellipse(50% 100% at 50% 170%)",
          }}
        >
          <p className="text-white text-center absolute bottom-1 lg:bottom-2 left-1/2 transform -translate-x-1/2 lg:text-lg md:text-base text-xs">
            Scroll for more
          </p>
        </div>
      </div>

      {/* on desktop bottom scroll */}
      <div
        id="scroll-home-desktop"
        className={`${
          isScrolled ? "lg:flex" : "hidden"
        } w-full h-[90vh] px-20 gap-5 mt-16 hidden justify-between items-stretch overflow-hidden translate-y-10 animate-slide-in`}
      >
        <div className="flex gap-5 lg:mt-20 justify-start items-center self-baseline">
          <div className="flex flex-col gap-5 max-w-[56%]">
            <p
              className="text-5xl font-bold w-24"
              style={{
                fontFamily: "Lexend",
              }}
            >
              The
            </p>

            <p
              className="font-bold w-auto lg:text-5xl md:text-4xl text-7xl"
              style={{
                fontFamily: "Lexend",
              }}
            >
              Social Commerce
            </p>

            <p
              className="text-[#4A5B63] w-[60%] text-md max-xl:text-7xl lg:text-xl md:text-sm"
              style={{
                fontFamily: "Lexend",
                // fontSize: ,
                marginTop: "10px",
              }}
            >
              Socco is a melting pot of consumers, businesses and brands,
              seamlessly blending storytelling with shopping.
            </p>
          </div>

          <button
            onClick={() => setOpen(true)}
            className="bg-gradient-to-r from-[#EE8A25] to-[#BC1008] text-white px-4 py-2 rounded-full hover:bg-orange-600 w-40 h-10"
          >
            <p className="font-bold">Get Socco</p>
          </button>
        </div>

        {/* <div className="-ml-40">
          <button
            onClick={() => setOpen(true)}
            className="bg-gradient-to-r from-[#EE8A25] to-[#BC1008] text-white px-4 py-2 rounded-full hover:bg-orange-600 w-40 mt-64"
          >
            <p className="font-bold">Get Socco</p>
          </button>
        </div> */}

        <div className="flex justify-end" id="mobile-frame">
          <img src={mobile} alt="Mobile" className="w-full lg:w-5/6 h-[80%]" />

          {/* Icons positioned randomly */}
          {icons.map((icon, index) => {
            const positions = [
              { top: "30%", right: "0%" }, //cart
              { top: "25%", right: "22%" }, //heart
              { top: "16%", right: "0%" }, //green review
              { top: "65%", right: "5%" }, //like
              { top: "15%", right: "20%" }, //users
              { top: "64%", right: "14%" }, //yellow review
              { top: "50%", right: "28%" }, //mic
            ];

            const position = positions[index]; // Map icon to its static position

            return (
              <img
                key={index}
                src={icon.icon}
                alt={`Icon ${index + 1}`}
                className="absolute h-12" // Adjust size here
                style={{
                  ...position, // Apply predefined positions
                  transform: "translate(-50%, -50%)", // Center icons
                  zIndex: 5, // Ensure visibility
                }}
              />
            );
          })}
        </div>

        {/* footer */}
        <div
          className="absolute gap-5 flex"
          style={{
            bottom: "10rem",
          }}
        >
          <a href="/privacy">
            <p
              style={{
                fontFamily: "Lexend",
              }}
            >
              Privacy
            </p>
          </a>

          <a href="/terms">
            <p
              style={{
                fontFamily: "Lexend",
              }}
            >
              Terms & Conditions
            </p>
          </a>
        </div>
      </div>

      {/* on mobile */}
      <div className="lg:hidden block w-full">
        <div
          className={`${
            isScrolled ? "flex" : "hidden"
          } lg:hidden translate-y-10 animate-slide-in`}
        >
          <div className="flex flex-col gap-5 md:mt-24 items-center w-full">
            <p
              className="md:text-5xl text-3xl font-bold"
              style={{
                fontFamily: "Lexend",
                textAlign: "center",
              }}
            >
              The
            </p>

            <p
              className="md:text-6xl text-4xl font-bold"
              style={{
                fontFamily: "Lexend",
                textAlign: "center",
              }}
            >
              Social Commerce
            </p>

            <p
              className="text-[#4A5B63]"
              style={{
                fontFamily: "Lexend",
                fontSize: "15px",
                marginTop: "10px",
                textAlign: "center",
              }}
            >
              Socco is a melting pot of consumers, businesses and brands,
              seamlessly blending storytelling with shopping.
            </p>

            <button
              onClick={() => setOpen(true)}
              className="bg-gradient-to-r from-[#EE8A25] to-[#BC1008] text-white px-4 py-2 rounded-full hover:bg-orange-600 w-[200px] self-center"
            >
              <p className="font-bold">Get Socco</p>
            </button>

            <img
              src={mobileLogo}
              alt="Logo"
              className="w-1/3 md:w-1/4 h-auto mt-10"
            />
          </div>
        </div>

        <div
          className={`${
            isScrolled ? "flex" : "hidden"
          } lg:hidden  absolute bottom-10 ms-5 translate-y-10 animate-slide-in gap-5 flex overflow-hidden self-start`}
        >
          <Link to="/privacy">
            <p
              style={{
                fontFamily: "Lexend",
              }}
              className="md:text-lg text-sm"
            >
              Privacy
            </p>
          </Link>

          <Link to="/terms">
            <p
              style={{
                fontFamily: "Lexend",
              }}
              className="md:text-lg text-sm"
            >
              Terms & Conditions
            </p>
          </Link>
        </div>
      </div>

      <Modal open={open} setOpen={setOpen} />
    </>
  );
}

export default Home;
